

















































































































































































.certificateServices{
    height: 100vh;
    overflow-y: scroll;
    .top-img{
        height: 24.6875rem;
        background-image: url("../../assets/loginIndex/certificateServices-img.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .block-item1{
        height: 32.8125rem;
        background-color: white;
        .block-item1-wrap{
            width: 73rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .left{
                padding-left: 1.0625rem;
                .title{
                    font-size: 1.4375rem;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .detail{
                    font-size: .9375rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .right{
                height: 25.4375rem;
                width: 30.1875rem;
                background-image: url("../../assets/loginIndex/zsfwct1.png");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .block-item2{
        height: 32.8125rem;
        background-color: #F9F9F9;
        .block-item2-wrap{
            width: 73rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .right{
                text-align: right;
                padding-right: 1.0625rem;
                .title{
                    font-size: 1.4375rem;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .detail{
                    font-size: .9375rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .left{
                height: 20.3125rem;
                width: 35rem;
                background-image: url("../../assets/loginIndex/zsfwct2.png");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .block-item3{
        height: 32.8125rem;
        background-color: white;
        .block-item3-wrap{
            width: 73rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .left{
                padding-left: 1.0625rem;
                .title{
                    font-size: 1.4375rem;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .detail{
                    font-size: .9375rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .right{
                height: 24.1875rem;
                width: 31.0625rem;
                background-image: url("../../assets/loginIndex/zsfwct3.png");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .block-item4{
        height: 32.8125rem;
        background-color: #F9F9F9;
        .block-item4-wrap{
            width: 73rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .right{
                text-align: right;
                padding-right: 1.0625rem;
                .title{
                    font-size: 1.4375rem;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .detail{
                    font-size: .9375rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .left{
                height: 20.9375rem;
                width: 38.9375rem;
                background-image: url("../../assets/loginIndex/zsfwct4.png");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .dialog-footer{
        .el-button:nth-child(2){
            background: #2B77FD;
        }
    }
}
.certificateServices {
    /deep/.el-dialog__wrapper {
        z-index: 9999 !important;
    }
    /deep/.el-dialog {
        background-color: transparent;
        border-radius: 20px;
    }
    /deep/.el-dialog__header {
        background: #fff;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        padding: 20px 20px 10px;
        .el-dialog__title {
            color: #333;
            font-size: 20px;
        }
        .el-dialog__headerbtn {
            top: 38px;
            .el-dialog__close {
                color: #333;
            }
        }
        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #333;
        }
    }
    /deep/.el-dialog__body {
        background: #fff;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        padding: 0 30px;
    }
    .contactTeacherPopup {
        padding-bottom: 57px;
        .dialogTop {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 45px;
            span {
                font-size: 23px;
                color: #333;
            }
        }
    }
}
